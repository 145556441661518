/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { useQueryParam, StringParam } from "use-query-params";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const Gitopadesh = () => {
  const [status] = useQueryParam("status", StringParam);

  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #ef1850 colortext = black colorprimary = #43190b colorsecondary = #43190b colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Heartfulness Donation" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation", event),
    []
  );
  return (
    <DonationLayout
      seoData={{
        title: "GITOPADESH COURSES - Heartfulness Education Trust",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#43190b",
          textAlign: "center",
          paddingTop: "10px",
        }}
      >
        {status === "success" && (
          <p
            sx={{
              color: "white",
            }}
          >
            Thank vou for registering. Gitopadesh program proctor will contact
            vou shortly.
          </p>
        )}
        <h3
          sx={{
            color: "white",
          }}
        >
          Pay Forward - Your contribution can help us take Gitopadesh to many
          more hearts!
        </h3>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(36, "DONATE NOW")}
          />
        </Box>
      </Box>
      <section>
        <Box
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(http://awsstaging.heartfulness.org/education/wp-content/uploads/2021/10/Untitled-1@2x.png)`,
            minHeight: "448px",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            "@media screen and (max-width: 540px)": {
              backgroundPosition: "top center",
              minHeight: "280px",
            },
          }}
          className="row banner-bg"
        >
          <div
            className="col-md-12 btn-btm"
            sx={{
              position: "absolute",
              bottom: "3%",
              width: "100%",
              textAlign: "center",
              "@media screen and (max-width: 540px)": {
                bottom: "0%",
                textAlign: "center",
              },
            }}
          />
        </Box>
      </section>

      <section className="py-5 bg-color" sx={{ backgroundColor: "#f6f7fb" }}>
        <div
          className="container"
          sx={{
            "@media (min-width:768px) and (max-width:912px)": {
              maxWidth: "inherit",
            },
          }}
        >
          <div className="row pb-4">
            <div className="col-md-12 text-center ">
              <h2
                className="text-uppercase gotham-bold"
                sx={{
                  font: "normal normal bold 26px/28px gotham-book",
                  letterSpacing: "0px",
                  color: "#2B2B2B",
                  opacity: "1",
                  fontWeight: "bold",
                }}
              >
                Gitopadesh courses
              </h2>
            </div>
          </div>

          <div className="row gap">
            <div className="col-lg-4 col-md-12 text-center my-1">
              <div
                className="bg-white p-4"
                sx={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 6px 18px #00000021",
                  borderRadius: "10px",
                  opacity: "1",
                  height: "100%",
                  "@media (min-width:768px) and (max-width:912px)": {
                    padding: "18px 0px",
                  },
                }}
              >
                <div className="title-img text-center py-2">
                  <img
                    loading="lazy"
                    src="https://cdn-web.heartfulness.org/education/wp-content/uploads/2022/04/prajna.png"
                    alt="..."
                    width="146px"
                    height="146px"
                    id="img-m"
                  />
                </div>
                <p
                  className="my-3"
                  sx={{
                    font: "normal normal bold 20px/22px gotham-book",
                    letterSpacing: "0px",
                    color: "#ED0B4C",
                    opacity: "1",
                    textTransform: "uppercase",
                    "@media (min-width:768px) and (max-width:912px)": {
                      fontSize: "19px",
                    },
                  }}
                >
                  PRAJNA
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Self-paced course <br />
                  for CHILDREN
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Language - English
                </p>
                <p
                  className="text-age"
                  sx={{
                    font: "normal normal bold 20px/24px Script MT",
                    letterSpacing: "0px",
                    color: "#011124",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                    "@media (min-width:768px) and (max-width:912px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  Age: 5-15 years
                </p>
                <p
                  className="text-batch"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-medium",
                    letterSpacing: "0px",
                    color: "#28292A",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Anytime at your own pace
                </p>
                <p
                  className="button-reg py-2"
                  sx={{
                    "@media (min-width: 1024px)": {
                      position: "relative",
                      bottom: "-7em",
                    },
                  }}
                >
                  <a
                    href="https://learning.heartfulness.org/courses/GPDPJ"
                    className="reg-btn text-decoration-none text-white"
                    target="blank"
                    sx={{
                      padding: "12px 26px",
                      background: "#29BBF8 0% 0% no-repeat padding-Box",
                      borderRadius: "6px",
                      opacity: "1",
                      font: "normal normal normal 14px/21px gotham-book",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      ":hover": { color: "#FFFFFF" },
                    }}
                  >
                    Know More & Register
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 text-center my-1">
              <div
                className="box bg-white p-4"
                sx={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 6px 18px #00000021",
                  borderRadius: "10px",
                  opacity: "1",
                  height: "100%",
                  "@media (min-width:768px) and (max-width:912px)": {
                    padding: "18px 0px",
                  },
                }}
              >
                <div className="title-img text-center py-2">
                  <img
                    loading="lazy"
                    src="https://cdn-web.heartfulness.org/education/wp-content/uploads/2022/04/stithaprajna.png"
                    alt="..."
                    width="146px"
                    height="146px"
                    id="img-m"
                  />
                </div>
                <p
                  className="text-red my-3"
                  sx={{
                    font: "normal normal bold 20px/22px gotham-book",
                    letterSpacing: "0px",
                    color: "#ED0B4C",
                    opacity: "1",
                    textTransform: "uppercase",
                    "@media (min-width:768px) and (max-width:912px)": {
                      fontSize: "19px",
                    },
                  }}
                >
                  STITHAPRAJNA
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Online
                  <br />
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Trainer-led course <br />
                  for ADULTS
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Language - Telugu & English
                </p>
                <p
                  className="text-age"
                  sx={{
                    font: "normal normal bold 20px/24px Script MT",
                    letterSpacing: "0px",
                    color: "#011124",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                    "@media (min-width:768px) and (max-width:912px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  Age: 22 years & above
                </p>
                <p
                  className="text-batch mb-2"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-medium",
                    letterSpacing: "0px",
                    color: "#28292A",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Season 4 – Starts October 4th (English)
                </p>
                <p
                  className="text-batch mb-2"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-medium",
                    letterSpacing: "0px",
                    color: "#28292A",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                    paddingBottom: "2.5em",
                  }}
                >
                  Season 4 – Starts October 3rd (Telugu)
                </p>
                <p className="button-reg py-2">
                  <a
                    href="https://heartfulness.org/education/stithaprajna-course/"
                    className="reg-btn text-decoration-none text-white"
                    target="blank"
                    sx={{
                      padding: "12px 26px",
                      background: "#29BBF8 0% 0% no-repeat padding-Box",
                      borderRadius: "6px",
                      opacity: "1",
                      font: "normal normal normal 14px/21px gotham-book",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      ":hover": { color: "#FFFFFF" },
                    }}
                  >
                    Know More & Register
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 text-center my-1">
              <div
                className="box bg-white p-4"
                sx={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 6px 18px #00000021",
                  borderRadius: "10px",
                  opacity: "1",
                  height: "100%",
                  "@media (min-width:768px) and (max-width:912px)": {
                    padding: "18px 0px",
                  },
                }}
              >
                <div className="title-img text-center py-2">
                  <img
                    loading="lazy"
                    src="https://cdn-web.heartfulness.org/education/wp-content/uploads/2022/04/vidyarthi.png"
                    alt="..."
                    width="146px"
                    height="146px"
                    id="img-m"
                  />
                </div>
                <p
                  className="text-red my-3"
                  sx={{
                    font: "normal normal bold 20px/22px gotham-book",
                    letterSpacing: "0px",
                    color: "#ED0B4C",
                    opacity: "1",
                    textTransform: "uppercase",
                    "@media (min-width:768px) and (max-width:912px)": {
                      fontSize: "19px",
                    },
                  }}
                >
                  VISHVASAKSHI
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Trainer-led course <br />
                  for YOUTH
                </p>
                <p
                  className="text-desc"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-book",
                    letterSpacing: "0px",
                    color: "#898989",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Language - English
                </p>
                <p
                  className="text-age"
                  sx={{
                    font: "normal normal bold 20px/24px Script MT",
                    letterSpacing: "0px",
                    color: "#011124",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                    "@media (min-width:768px) and (max-width:912px)": {
                      fontSize: "14px",
                    },
                  }}
                >
                  Age: 12 to 17 years
                </p>
                <p
                  className="text-batch"
                  sx={{
                    font: "normal normal normal 15px/20px gotham-medium",
                    letterSpacing: "0px",
                    color: "#28292A",
                    opacity: "1",
                    marginBottom: "1.5rem !important",
                  }}
                >
                  Season 4 – Starts October 7th
                </p>
                <p
                  className="button-reg py-2"
                  sx={{
                    "@media (min-width: 1024px)": {
                      position: "relative",
                      bottom: "-7em",
                    },
                  }}
                >
                  <a
                    href="https://heartfulness.org/education/vishvasakshi-course/"
                    className="reg-btn text-decoration-none text-white"
                    target="blank"
                    sx={{
                      padding: "12px 26px",
                      background: "#29BBF8 0% 0% no-repeat padding-Box",
                      borderRadius: "6px",
                      opacity: "1",
                      font: "normal normal normal 14px/21px gotham-book",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      ":hover": { color: "#FFFFFF" },
                    }}
                  >
                    Know More & Register
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DonationLayout>
  );
};

export default Gitopadesh;
